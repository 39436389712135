
import { Link } from "react-router-dom";
import { FaSquareGithub,FaLinkedin,FaSquareFacebook, FaWhatsapp ,FaLocationDot } from  "react-icons/fa6";
import { SiGmail } from "react-icons/si";


function Header({showMenu,setShowMenu,user}) {
   
    return(
        <header>
        <div>
            <div className={`menu ${showMenu?`rotate`:``}`}  onClick={()=>setShowMenu(!showMenu)}>
            <div></div>
            <div></div>
            <div></div>
            </div>
        <Link to="/"><h1><img src="/logo.png" width='fit-content'/></h1></Link>
        </div>
        <ul className={showMenu?`show-menu`:""}>
            <Link to="/" onClick={()=>setShowMenu(!showMenu)}><li>الصفحة الرئيسية</li></Link>
            <Link to="/about" onClick={()=>setShowMenu(!showMenu)}><li>عن جوفنتس</li></Link>
            {/* <Link to="/cars" onClick={()=>setShowMenu(!showMenu)}><li>جميع السيارات</li></Link> */}
            <Link to="/contact" onClick={()=>setShowMenu(!showMenu)}><li>تواصل معنا</li></Link>
            {user&&<Link to="/logina2" onClick={()=>localStorage.clear()}><li>{`تسجيل الخروج`}</li></Link>}
            {/* <li>About</li>
            <li>Projects</li>
            <li>Contact</li> */}
            {/* <li id="socil"> */}

            <div className="footer-icons">
            <Link to="https://wa.me/+971556678100" target="_blank" ><FaWhatsapp/></Link>
            <Link to="https://maps.app.goo.gl/EA6YUoYxHY1snJnH6?g_st=com.google.maps.preview.copy" target="_blank" ><FaLocationDot/></Link>
            </div>

        {/* <Link to="https://wa.me/+971556678100" target="_blank" ><FaWhatsapp/></Link> */}
        {/* <Link to="https://maps.app.goo.gl/EA6YUoYxHY1snJnH6?g_st=com.google.maps.preview.copy" target="_blank" ><FaLocationDot/></Link> */}
        {/* <Link to="https://github.com/249joe" target="_blank" ><FaSquareGithub/></Link> */}
        {/* <Link to="https://mail.google.com" target="_blank" ><SiGmail/></Link> */}
            {/* </li> */}
        </ul>
        

       
        </header>
    )
}
export default Header;
