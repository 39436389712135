import { useState } from "react";
import Card from "./Card";
import "./cars.css"
import './phone.css'
function Cars({data ,isAdmin,allData, companys}) {
    let [filterD,setFilterD ]= useState(data);
    function handleFilter() {
        let filterData = data;
        let model = document.getElementById("model");
        let price = document.getElementById("price");
        let kilos = document.getElementById("kilos");
        let company = document.getElementsByName("company")[0];
        if(model.value||price.value||company.value){
            filterData = allData
        }
        model.value? filterData=filterData.filter((item)=>item.model == model.value):filterData=filterData;
        price.value? filterData=filterData.filter((item)=>item.priceM <= price.value):filterData=filterData;
        // kilos.value? filterData=filterData.filter((item)=>item.kilos <= kilos.value):filterData=filterData;
        company.value? filterData=filterData.filter((item)=>item.company == company.value):filterData=filterData;
        setFilterD(filterData)
    }
return (
    <main className="cars-route" id="cars-route">
        {/* <h1>قم باستخدام الفلاتر لايجاد السيارة المناسبة -ادخل الارقام باللغة الانجليزية</h1> */}
        <div className="filter">
        <div>
        <h1>الموديل</h1>
        <input type="text" placeholder="الموديل" id="model"/>
        </div>
        <div>
        <h1>السعر</h1>
        <input type="text" placeholder="اقصي سعر باليوم" id="price"/>
        </div>
        <div>
        <h1>الشركة</h1>
        {/* <input type="text" placeholder="كيلومتر" id="kilos"/> */}
        <select name="company">
            <option value=''>الشركة</option>
            {companys.length >0&&companys.map((c)=>{
                return(
                    <option value={c.name}>{c.name}</option>
                )
            })}
        </select>
        </div>
        <button onClick={()=>handleFilter()}>بحث</button>
        </div>
        <div className="cards">
        {filterD.length ==0&& <div><h1 id="err-txt">للاسف ! لم يتم العثور علي  سيارة</h1>
        <img src="/images/sickcar.png" id="err-img" alt="search not found" style={{marginTop:"10px"}}/></div>}

     {filterD.map((item)=>{
         return(
            <Card data = {item} isAdmin={isAdmin}/>
         )
    })}
    </div>
    </main>
)    
}
export default Cars