import apiReq from "./apiReq";
function AddCompany({url}) {
    async function demo(e) {
        let company = document.getElementsByName("name")[0].value;
        e.preventDefault();
        let myurl = url+"/companys";
        if(company){
            try{
                const options = {
                    method:'POST',
                    header:{
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Connection': 'keep-alive',
                        'Accept': '*/*'
                    },
                    body : new URLSearchParams({"name":company})
                }
                const result = await apiReq(myurl,options);
            }catch(err){
                console.log(err);
            }finally{
                window.location.reload()
            }
        }else{
            alert("يجب ان تدخل اسم الشركة")
        }
        // window.location.reload()
    }
    return(
        <div>
            <form action="http://localhost:3003/companys" method="post" onSubmit={(e)=>demo(e)} >
            <input type="txt" placeholder="company" name="name"/>
            <button type="submit">submit</button>
        </form>
        </div>
    )
}
export default AddCompany