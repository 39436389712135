import { FaPhone ,FaLocationDot,FaWhatsapp } from "react-icons/fa6"
import { SiGmail } from "react-icons/si";
import apiReq from "./apiReq";
import { Link } from "react-router-dom";
function Contact({url}) {
    async function demo(e,url) {
        let phone = document.getElementsByName("phone")[0].value;
        let mes = document.getElementsByName("mes")[0].value;
        e.preventDefault();
        // let url = `/messages`;
        if(phone&&mes){    
            try{
                const options = {
                    method:'post',
                    header:{
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Connection': 'keep-alive',
                        'Accept': '*/*'
                    },
                    body : new URLSearchParams({"phone":phone,"mes":mes})
                }
                const result = await apiReq(url+"/messages",options);
            }catch(err){
                console.log(err);
            }finally{
    
                window.location.reload()
            }
        }else{
            alert("يجب ان تدخل رقم الهاتف والرسالة")
        }
    }
    return(
        <main className="contact" id="contact">
            <form action="http://localhost:3003/companys" method="post" className="contact-mes" onSubmit={(e)=>demo(e,url)}>
                <h1>ارسل لنا رسالة</h1>

                <label htmlFor="phone">رقم الهاتف</label>
                <input id='phone' name='phone' type="text" placeholder="ادخل رقم هاتفك"/>
                <label htmlFor="mes" >الرسالة</label>
                <textarea type="text" name="mes" id="mes" placeholder="نص الرسالة"/>
                <button type="submit">إرسال</button>
            </form>
            <div className="contact-info">
                <h1>معلومات الاتصال</h1>
                <h2>الهاتف</h2>
                <h2><FaPhone/> <span>+971 55 667 8100 </span></h2>
                <h2>البريد الالكتروني</h2>
                <h2><SiGmail/> <span>juventusrentacar@gmail.com</span></h2>
                <Link to='https://maps.app.goo.gl/EA6YUoYxHY1snJnH6?g_st=com.google.maps.preview.copy'  target="_blank"><button><FaLocationDot/> موقعنا</button></Link>
                <Link to='https://wa.me/+971556678100' target="_blank"><button id="green"><FaWhatsapp/> واتساب</button></Link>
            </div>
        </main>
    )
}
export default Contact