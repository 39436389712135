import { FaGlobe, FaWhatsapp } from "react-icons/fa6"
import { Link, useParams } from "react-router-dom"
import axios from "axios";
import { useState } from "react";
function EditCar({data,companys,url}) {
    const {id} = useParams()
    let carInfo1 = data.filter(item =>  item._id   )

    let carInfo = {}
    for (let i = 0; i < carInfo1.length; i++) {
        if(carInfo1[i]._id === id){
            carInfo = carInfo1[i]
        }
    }
    let text = `مرحبا ارغب بحجز ${carInfo.name} ${'موديل'} ${carInfo.model} ${carInfo.company} ${carInfo.priceM}`
    // console.log(carInfo);
    let [name1,setname] = useState(carInfo.name)
    let [model,setmodel] = useState(carInfo.model)
    let [status,setstatus] = useState(carInfo.status)
    let [company,setcompany] = useState(carInfo.company)
    let [available,setavailable] = useState(carInfo.available)
    let [priceM,setpriceM] = useState(carInfo.priceM)
    let [special,setspecial] = useState(carInfo.special)
        async function handle(e,id) {
            e.preventDefault()
            let date = new Date();
                let currentMonth = String(date.getMonth()+1).padStart(2,"0")
                let currentDay= String(date.getDate()).padStart(2, '0')
                let fullDate = `${date.getFullYear()}/${currentMonth}/${currentDay}`
                console.log({
                            "name":name1,"model":model,"status":status,"company":company,"available":available,"priceM":priceM,"special":special,"date":fullDate
                         });

           if(priceM&&name1&&model&&status&&company&&fullDate){
            try{

                
                await axios.put(url+'/items/'+id, {
                    "name":name1,"model":model,"status":status,"company":company,"available":available,"priceM":priceM,"special":special,"date":fullDate
                }
            )
        }catch(err){
            console.log(err);
        }finally{
            window.location.reload()
        }
        }else{
            alert("يجب الا يكون هناك حق بينات فارغ")
        } 
        }
    return(
        <div className="oneCar oneCarupdate">
                <div className="oneCar-cover" style={{backgroundImage:`url(${carInfo.img})`}} >

                </div>
                
                <form action="http://localhost:3000/items" method="post" onSubmit={(e)=>handle(e,carInfo._id)}>
            <span>name: </span>
            <input type="txt" placeholder="name" name="name" value={name1} onChange={(e)=>setname(e.target.value)}/>
            <span>model: </span>
            <input type="txt" placeholder="model" name="model" value={model} onChange={(e)=>setmodel(e.target.value)}/>
            <span>price: </span>
            <input type="txt" placeholder="priceM" name="priceM" value={priceM} onChange={(e)=>setpriceM(e.target.value)}/>
            <span>company: </span>
            <select name="company" value={company} onChange={(e)=>setcompany(e.target.value)}>
                {companys.map((c)=>{
                    return(
                        <option value={c.name}>
                            {c.name}
                        </option>
                    )
                })}
            </select>
                <span>status: </span>
            <select name="status" value={status} onChange={(e)=>setstatus(e.target.value)}>
                <option value='excellent'>excellent</option>
                <option value='medium'>medium</option>
            </select>
            <div>

                <span>available: </span>
            <input type="checkbox" name="available" checked={available}  onClick={()=>setavailable(!available)}/>
            </div>
            <div>

                <span>special: </span>
            <input type="checkbox" name="special" checked={special}  onClick={()=>setspecial(!special)}/>
            </div>
            <button type="submit">update</button>
        </form>
                
        </div>
    )
}

export default EditCar