import Artical from "./Artical"
import "./artical.css"
function About() {
    return(
        <main className="about">
            <div className="about-info">
            <h1>نبذة عن مكتب جوفنتس لتأجير السيارات
            </h1>
            <p>
في مكتب جوفنتوس، نحن نسعى دائماً لتقديم خدمة متميزة ترتقي لتطلعات عملائنا وتحقق رضاهم الكامل. هدفنا هو توفير تجربة تأجير سيارات مرنة ومريحة تضمن الراحة والرضا للجميع. من خلال مكتبنا في مدينة العين دار الزين، نحن نؤمن بأن العميل يستحق أفضل تجربة ممكنة، لذا نحرص على تقديم مجموعة متنوعة من السيارات الحديثة والمريحة التي تلبي كافة الاحتياجات. سواء كنت تبحث عن سيارة فاخرة أو سيارة اقتصادية، فإننا نقدم لك الحلول المثلى التي تناسب جميع الأذواق والميزانيات. نحن هنا لخدمتك وتوفير الراحة لك في كل خطوة من خطوات تأجير السيارات.
</p>
            </div>
            <div className="about-servces">
                <h1>خدماتنا</h1>
                
                    <h2>تأجير السيارات الحديثة</h2>
                    <li>نوفر مجموعة واسعة من السيارات الحديثة والمجهزة بأحدث التقنيات لضمان راحتكم وسلامتكم أثناء تجوالكم. يمكنكم الاختيار من بين مختلف الأحجام والفئات، سواء كنتم تبحثون عن سيارة صغيرة للتنقل في المدينة أو سيارة فاخرة لرحلة خاصة.
</li>
<h2>تأجير السيارات لفترات قصيرة وطويلة
</h2>
                    <li>نوفر خدمات تأجير السيارات للفترات القصيرة والطويلة، مع خيارات مرنة تلبي احتياجات كافة العملاء، سواء كانوا سياحًا يزورون المنطقة أو مقيمين يبحثون عن حلول مؤقتة لاحتياجاتهم اليومية.
                    </li>
                    <h2>   دعم على مدار الساعة</h2>
                    <li>ريقنا المحترف متاح على مدار الساعة لتوفير الدعم والمساعدة، ونضمن لكم تجربة تأجير سيارة سلسة ومريحة نحن ملتزمون بتقديم أفضل خدمة عملاء ممكنة، ونؤكد لكم أننا موجودون دائمًا لمساعدتكم.</li>
                
            </div>
            <div className="about-t">
            <h1>
            لماذا تختارون مكتب جوفنتس</h1>
            <p>
            نقدم في مكتب جوفنتس لتأجير السيارات العديد من المزايا التي تجعلنا الخيار الأمثل لاحتياجاتكم من تأجير السيارات في مدينة العين.</p>
            <h2>مجموعه متنوعه
            </h2>
            <p>
            نوفر مجموعة واسعة من السيارات الحديثة والمجهزة بأحدث التقنيات لتلبية احتياجات جميع عملائنا.</p>

            <h2>أسعار تنافسية</h2>
            <p>نقدم أسعارًا تنافسية تناسب جميع الميزانيات</p>
            <h2>خدمة عملاء ممتازة</h2>
            <p>فريقنا المحترف متاح على مدار الساعة لتوفير الدعم والمساعدة.</p>
            <h2>مرونة في الحجز</h2>
            <p>نتيح لك سهولة حجز السيارة عبر الإنترنت أو من خلال الاتصال بنا. كما نقدم خيارات استلام وتسليم السيارة المرنة لتناسب جدولك الزمني</p>
            <h2>موقع مناسب</h2>
            <p>يقع مكتبنا في حي النيادات في وسط البلد ويسهل الوصول إليه.
            </p>
            <p>
            اختيار جوفنتس لتأجير السيارات يضمن لك تجربة تأجير سلسة ومريحة. اتصل بنا اليوم وابدأ رحلتك معنا!</p>

            </div>
            <Artical />
        </main>
    )
}

export default About