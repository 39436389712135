import { FaLocationDot, FaWhatsapp } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Footer({length}) {
    let date = new Date();
    
    return(
        <footer>
            <div className="footer-info">
            <div className="footer-icons">
            <Link to="https://wa.me/+971556678100" target="_blank" ><FaWhatsapp/></Link>
            <Link to="https://maps.app.goo.gl/EA6YUoYxHY1snJnH6?g_st=com.google.maps.preview.copy" target="_blank" ><FaLocationDot/></Link>
            </div>
            <div>
            <h1>جوفنتس</h1>
            <p>تجربة مريحة</p>
            </div>
            </div>
            <div className="underline">
            </div>
            <div>
            <span>ابوظبي، العين النيادات</span>
            &copy;جميع الحقوق محفوظة جوفنتس {date.getFullYear}
            </div>
        </footer>
    )
}

export default Footer;