import { useState } from "react"
import axios from "axios";

function LoginPage({url}) {

    let [user,setUser] = useState()
    let [passwd,setPasswd] = useState()

   async function handle() {
        await axios.post(url+'/users', {
            user: user,
            password: passwd
          })
          .then(function (response) {
            if(response.data.user){
                localStorage.setItem('user',response.data.user)
                localStorage.setItem("token",response.data.token)
                window.location.href = "/admina2"
                console.log(response.data.user);
                return;
            }
            // localStorage.clear()
            alert("username or password is wrong")
          })
          .catch(function (error) {
            console.log(error);
            alert("username or password is wrong")
          });
    }

       return(
        <div className="login">
            <form>
                <h1>Login</h1>
                <input type="text" placeholder="User Name" value={user} onChange={(e)=>setUser(e.target.value)}/>
                <input type="password" placeholder="Password" value={passwd} onChange={(e)=>setPasswd(e.target.value)}/>
                <div className="login-btn" onClick={()=>handle()}>Login</div>
            </form>

        </div>
       )
}

export default LoginPage