import { FaGlobe, FaWhatsapp } from "react-icons/fa6"
import { Link, useParams } from "react-router-dom"

function Car({data}) {
    const {id} = useParams()
    let carInfo1 = data.filter(item =>  item._id   )

    let carInfo = {}
    for (let i = 0; i < carInfo1.length; i++) {
        if(carInfo1[i]._id === id){
            carInfo = carInfo1[i]
        }
    }
    let text = `مرحبا ارغب بحجز ${carInfo.name} ${'موديل'} ${carInfo.model} ${carInfo.company} ${carInfo.priceM}`
    console.log(carInfo);
    return(
        carInfo.name?<div className="oneCar">
                <div className="oneCar-cover" style={{backgroundImage:`url(${carInfo.img})`}}>
                </div>
                <div className="oneCar-info">

                <h1>{carInfo.name}</h1>
                <h1>{carInfo.model}</h1>
                <h1>{carInfo.status}</h1>
                <div> {carInfo.company} <FaGlobe/></div>
                <h1><span>الحالة</span>{carInfo.status == 'excellent'? ' ممتازة ':' متوسطة '}</h1>
                <h1><span>سعر الايجار </span>{" "+carInfo.priceM +" درهم"}</h1>
                    <Link to={`https://api.whatsapp.com/send/?phone=%2B971556678100&text=${text}&type=phone_number&app_absent=0`} target="_blank" id="green">
                    <div>
                    <FaWhatsapp/> 
                    <h1>
                         احجز الان
                    </h1>
                    </div>
                    </Link>
                </div>
        </div>:<h3>car not found</h3>
    )
}

export default Car