import apiReq from "./apiReq"
function MessagesApi({messages,url}) {
    async function handleDelete(id) {
        const myurl = url+"/messages/"+id
        const options = {
            method:"DELETE"
        }
        const result = await apiReq(myurl,options)
        window.location.reload(true)
    }
    let date = new Date();
    return(
        <div className="admin-info-r">
            <div>
                <span>Numper</span>
                <span>MESSAGES</span>
                <span>DELETE</span>
            </div>
            {messages.length>0?messages.map((item)=>{

                return(
                    
                    <div>
                <span>{item.phone}</span>
                <span>{item.mes}</span>
                <button onClick={()=>handleDelete(item._id)}>delete</button>
            </div>
            )
                }):null
            }
        </div>
    )
}
export default MessagesApi