import "./artical.css"
import './phone.css'
function Artical() {
    return(
        <div id="artical">
            <h1>نصائح لاستئجار سيارة</h1>
            <ul>
                <li>قرر نوع السيارة التي تحتاجها
                اعتمادًا على غرض رحلتك وعدد الركاب، قرر نوع السيارة التي ترغب في استئجارها. سيزجي رينت آ كار عادةً ما تقدم خيارات متعددة للسيارات، لذا من المهم اختيار سيارة تتناسب مع احتياجاتك. إذا كنت تسافر مع العائلة، فقد تكون السيارة الأكبر هي الخيار الأفضل. إذا كنت تسافر وحدك أو مع مجموعة صغيرة، فإن الخيار الاقتصادي قد يكون كافيًا.</li>
                <li>استكشف خيارات التأمين
                التأمين أمر حاسم عند استئجار السيارة. شركات التأجير الموثوقة مثل سيزجي رينت آ كار عادةً ما تقدم خيارات تأمين متعددة. التأمين ضد الحوادث والسرقة والضرر سيساعدك في تجنب المخاطر المالية المحتملة. قم بمراجعة خيارات التأمين بعناية واختر خطة تتناسب مع احتياجاتك.</li>
                <li>تحقق من مستوى الوقود: تأكد من مستوى الوقود قبل تأجير السيارة وأعد السيارة بنفس مستوى الوقود الذي وجدته عند تأجيرها.</li>
                <li>تجنب القيادة في ساعات الذروة: تجنب القيادة في ساعات الذروة، حيث يكون الازدحام على الطرق أكثر، ويمكن أن تستغرق الرحلات وقتا أطول.</li>
                <li>حافظ على السيارة.</li>
            </ul>
        </div>
    )
}

export default Artical