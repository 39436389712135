import { useState } from "react";
import apiReq from "./apiReq";
import axios from "axios";

    function AddCar({companys,url}) {
        let [filee,setFile] = useState()
        async function demo(e) {
            const formData = new FormData()
            console.log(formData);
            formData.append('img',filee)
            // formData.append('fileName', filee.name);
            console.log(formData);
            let name = document.getElementsByName("name")[0].value;
            let model = document.getElementsByName("model")[0].value;
            // let kilos = document.getElementsByName("kilos")[0].value;
            let priceM = document.getElementsByName("priceM")[0].value;
            let status = document.getElementsByName("status")[0].value;
            let company = document.getElementsByName("company")[0].value;
            let available = document.getElementsByName("available")[0].checked;
            let special = document.getElementsByName("special")[0].checked;
            // let file = document.getElementsByName("file")[0];
            e.preventDefault();
            let url = "http://localhost:3003/items";
            const options = {
                method:'POST',
                header:{
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Connection': 'keep-alive',
                    'Accept': '*/*'
                },
                // formData,
                body : new URLSearchParams({"name":name,"model":model,"img":formData,"status":status,"company":company,"available":available,"priceM":priceM})
            }
            const result = await apiReq(url,options);
            
        }
        function handleChange(e) {
    setFile(e.target.files[0])
    console.log(e.target.files[0]);
        }
        let date = new Date();
        let currentMonth = String(date.getMonth()+1).padStart(2,"0")
        let currentDay= String(date.getDate()).padStart(2, '0')
        let fullDate = `${date.getFullYear()}/${currentMonth}/${currentDay}`
        console.log(fullDate);
        console.log(date);
        async function handleUplode(e) {
            e.preventDefault()
            let name = document.getElementsByName("name")[0].value;
            let model = document.getElementsByName("model")[0].value;
            // let kilos = document.getElementsByName("kilos")[0].value;
            let priceM = document.getElementsByName("priceM")[0].value;
            let status = document.getElementsByName("status")[0].value;
            let company = document.getElementsByName("company")[0].value;
            let available = document.getElementsByName("available")[0].checked;
            let special = document.getElementsByName("special")[0].checked;
            const formData = new FormData()
            console.log(formData);
            formData.append('img',filee)
            let body = {"name":name,"model":model,"status":status,"company":company,"available":available,"priceM":priceM,"special":special,"date":fullDate}
            formData.append('body',JSON.stringify(body))
            
            if(priceM&&name&&model&&status&&company&&fullDate&&filee){
                    try{
                    
                    const response = await axios.post(`${url}/items`, formData, { headers: { "Content-Type": 'application/x-www-form-urlencoded',
                        'Connection': 'keep-alive',
                        'Accept': '*/*'
                    }
                    
                    // body:new URLSearchParams({"name":name,"model":model,"status":status,"company":company,"available":available,"priceM":priceM,"special":special,"date":fullDate})
                })
            }catch(err){
                console.log(err);
            }finally{
                window.location.reload()
            }
        }else{
            alert("قم بادخال جميع البيانات")
        }

            // formData.append('fileName', filee.name)
        }
        return(
        <form action="http://localhost:3000/items" method="post" onSubmit={(e)=>demo(e)}>
            <span>name: </span>
            <input type="txt" placeholder="name" name="name"/>
            <span>model: </span>
            <input type="txt" placeholder="model" name="model"/>
            {/* <span>kilos: </span>
            <input type="txt" placeholder="kilos" name="kilos"/> */}
            <span>price: </span>
            <input type="txt" placeholder="price" name="priceM"/>
            <span>company: </span>
            <select name="company">
                {companys.map((c)=>{
                    return(
                        <option value={c.name}>
                            {c.name}
                        </option>
                    )
                })}
            </select>
                <span>status: </span>
            <select name="status">
                <option value='excellent'>excellent</option>
                <option value='medium'>medium</option>
            </select>
                <span>available: </span>
            <input type="checkbox" name="available"/>
                <span>special: </span>
            <input type="checkbox" name="special"/>
                <span>image: </span>
            <input type="file" name="file" onChange={(e)=>handleChange(e)}/>
            <button onClick={(e)=>handleUplode(e)} >submit</button>
        </form>

    )
}
export default AddCar;