import { Link, useSearchParams } from "react-router-dom";
import Artical from "./Artical";
import Card from "./Card"
import Cars from "./Cars";
import { useEffect, useState } from "react";


function Home({data, isAdmin , companys}) {
    const [searchParams, setSearchParams] = useSearchParams();
    let p = searchParams.get("page")
    // console.log("current"+currentPage);
    console.log(p);
    let Adata = data.filter(item=>item.available == true)
    
    let carsCount = 8
    let pagesArr = []
    let pages = Math.ceil(Adata.length/carsCount)
        for (let i = 0; i < pages; i++) {
            pagesArr.push(i+1)
        }
    let currentPage = (p>0&&p<=pages)?p:1

    
    // function handel(currentPage) {
         Adata = data.filter(item=>item.available == true)
        let shortData = [];
        let star = (currentPage-1)*carsCount
        let end = (currentPage-1)*carsCount
        console.log(star);
        if(Adata.length >= carsCount){
            for (let i = star ; i < carsCount+end; i++) {
                if(Adata[i]){

                    shortData.push(Adata[i])
                }
            }
            // setDd(shortData)
        }else{
            for (let i = 0; i < Adata.length; i++) {
                if(Adata[i]){
                    shortData.push(Adata[i])
                }
            }
            // setDd(shortData)
        }
    // }
    return(
        <main className="home">
    <div className="cover-back">
    <div className='cover'>
    <h1 >جوفنتس لإيجار السيارات</h1>    
    <p>استكشف افضل،  <span>ارخص</span> السيارات مع عروض اسبوعية و شهرية</p>  
    <Link to="/contact">
    <div className="home-btn">تواصل معنا</div>
    </Link>
     </div>
     </div>
     <Cars data={shortData} allData={Adata} isAdmin={isAdmin} companys={companys}/>
     {/* <h1>احدث السيارات</h1>
     <div className="cards">
     {shortData.length>0?shortData.map((item)=>{
        return(
            <Card data = {item} isAdmin={isAdmin}/>
            )
            }):<h1>no cars to render</h1>}
            </div>
            <Link to="/cars"> <h2>عرض جميع السيارات</h2></Link> */}
   <div className="pagesNav">
   {pagesArr.map((page)=><div onClick={()=>window.location.href=`/?page=${page}`}style={page==currentPage?{background:"var(--main-color)",color:"white"}:null} >{page}</div>)}
   </div>
   
    {/* <Artical /> */}
    <br/>
    <br/>
        </main>

    )
}
export default Home;