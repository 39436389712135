import './admin.css'
import './phone.css'
import { Link, Outlet } from "react-router-dom"
import {  BrowserRouter as Router,Route,Routes } from "react-router-dom";
import AddCar from "./AddCar";
import { FaPlus } from 'react-icons/fa6';
function Admin() {
    
    return(
        <main id="admin" className="admin">
            <div className="admin-rows">
                <h1>APIs</h1>

                    <div>
                    <h1><Link to='/admina2/cars'>Cars</Link></h1>
                    <h1><Link to='/admina2/addcar'><FaPlus id='plus-icon'/> add</Link></h1>
                    </div>
                    <div>
                <h1><Link to='/admina2/companys'>Companys</Link></h1>
                <h1><Link to='/admina2/addcompany'><FaPlus id='plus-icon'/> add</Link></h1>
                    </div>
                    <div>
                <h1><Link to='/admina2/messages'>Messages</Link></h1>
                    </div>
            </div>
            <div className="admin-info">
        <Outlet/>
            </div>
        </main>
       
    )
}

export default Admin