

async function apiReq(url='',optionObj=null,errMs=null) {
    try{

        const res = await fetch(url,optionObj)
        if(!res.ok)throw Error('err msg');
    }catch(err){
        errMs=err.message
    }finally{
        return errMs
    }
}
export default apiReq