
import { useEffect } from "react";
import AddCar from "./AddCar";
import "./card.css"
import './aboutAndContact.css'
import "./phone.css"
import Cars from "./Cars";
import Footer from "./Footer";
import Header from './Header';
import Home from './Home'
import {useState} from "react";
import {  BrowserRouter as Router,Route,Routes } from "react-router-dom";
import About from "./About";
import Contact from "./Contact";
import Admin from "./Admin";
import CarsApi from "./CarsApi";
import CompanysApi from "./CompanysApi";
import AddCompany from "./AddCompany";
import MessagesApi from "./MessagesApi";
import Car from "./Car";
import EditCar from "./EditCar";
import LoginPage from "./LoginPage";
// import Login from "./login";
// import Login from "./Login";
let isAdmin = false;

function App() {
  let [showMenu, setShowMenu] = useState(false);
  let [data , setData ] = useState([]);
let [companys,setCompanys] = useState(
  [
  // {
  //   name:"company1"
  // },
  // {
  //   name:"company2"
  // },
  // {
  //   name:"company3"
  // }

]
);
let [messages,setMessages] = useState([

  // {
  //   phone:"+249 249",
  //   mes:"demo message"
  // }
]);
let url = 'https://apia2.juventusuae.com'
useEffect(()=>{
const fetchItem = async()=>{
  try{

const res =  await fetch(url+"/items");
const data1 = await res.json();
console.log("--------------------");
console.log(data1);
if(data1){
  setData(data1.reverse())}
  // if(){
  // setData([{
  //   _id:1,
  //   name:" جي كلاســ",
  //   img:"/images/gclass.jpg",
  //   company:"company3",
  //   model:"2021",
  //   status:'excellent',
  //   available:true,
  //   kilos:200,
  //   priceM:20000,
  //   date:'2024/12/23'
  // }])
// }else{
//   setData([[
//     {
//         _id:1,
//         name:" كلاسـ",
//         img:"/images/gclass.jpg",
//         company:"company3",
//         model:"2021",
//         status:'excellent',
//         available:true,
//         kilos:200,
//         priceM:20000,
//         date:'2024/12/23'
//       },  
//       {
//         _id:2,
//       name:"مرسيدس بنز",
//       dis:"",
//       img:"/images/Mercedes.jpg",
//       company:"company3",
//         model:"2021",
//         status:'excellent',
//         available:true,
//         kilos:200,
//         priceM:20000
//     },
//       {
//         _id:3,
//       name:"كاي اكس 3",
//       dis:"",
//       img:"/images/Kaiyi.jpg",
//       company:"company3",
//         model:"2021",
//         status:'excellent',
//         available:true,
//         kilos:200,
//         priceM:20000
//     },
//       {
//         _id:4,
//       name:"دودج تشالنجر",
//       dis:"",
//       img:"/images/Dodge.jpg",
//       company:"company1",
//         model:"2021",
//         status:'excellent',
//         available:true,
//         kilos:200,
//         priceM:10000
//     },
//       {
//         _id:5,
//       name:"نيسان صني",
//       dis:"",
//       img:"/images/Nissan.jpg",
//       company:"company2",
//         model:"2021",
//         status:'excellent',
//         available:true,
//         kilos:400,
//         priceM:10000,
//           special:true
//     },
//       {
//         _id:6,
//       name:"جي كلاس",
//       dis:"",
//       img:"/images/gclass.jpg",
//       model:"2021",
//       status:'excellent',
//       available:true,
//       kilos:200,
//       priceM:20000,
//       special:true,
//       company:"company1"
//     }
  
//   ]])
// }


  }catch(err){
    console.log(err);
  }
}
fetchItem();
},[])
useEffect(()=>{
const fetchItem = async()=>{
  try{
const res =  await fetch(url+"/companys");
const data1 = await res.json();
if(data1){
  setCompanys(data1)
}else{
  setCompanys()
}

  }catch(err){
    console.log(err);
  }
}
fetchItem();
},[])
useEffect(()=>{
const fetchItem = async()=>{
  try{
const res =  await fetch(url+"/messages");
const data = await res.json();
setMessages(data)

  }catch(err){
    console.log(err);
  }
}
fetchItem();
},[])
let user = localStorage.getItem("user")
  return (
    <Router>

    <div className="App">
     <Header 
     showMenu={showMenu}
     setShowMenu={setShowMenu}
     user={user}
     />
     <div onClick={()=>setShowMenu(false)}>
     <Routes>
      
    <Route exact path="/" Component={()=><Home data={data} isAdmin={isAdmin} companys={companys}/>}/>
    <Route exact path="/cars/:id" Component={()=><Car data={data}/>}/>
    <Route exact path="/admina2/editcar/:id" Component={()=><EditCar data={data} companys={companys} url={url}/>}/>
    <Route exact path="/add" Component={()=><AddCar />}/>
    <Route exact path="/about" Component={()=><About />}/>
    <Route exact path="/contact" Component={()=><Contact url={url}/>}/>
    <Route exact path="/logina2" Component={()=>!user?<LoginPage url={url}/>:window.location.href='/admina2'}/>
    <Route exact path="/admina2" Component={()=>user?<Admin />:window.location.href='/logina2'}>
      <Route exact path="cars" Component={()=>user?<CarsApi data={data} url={url}/>:window.location.href='/logina2'}/>
      <Route exact path="addcar" Component={()=>user?<AddCar companys={companys} url={url}/>:window.location.href='/logina2'}/>
      <Route exact path="companys" Component={()=>user?<CompanysApi companys={companys} url={url}/>:window.location.href='/logina2'}/>
      <Route exact path="addcompany" Component={()=>user?<AddCompany url={url}/>:window.location.href='/logina2'}/>
      <Route exact path="messages" Component={()=><MessagesApi messages={messages} url={url}/>}/>
    </Route>
    {/* <Route path="*" Component={()=><h3>404 page not found</h3>} /> */}

     {/* <Route path="/admina2/*" Component={()=>{!user ?window.location.href='/logina2'}}/> */}
     
     </Routes>
     </div>
     <Footer/>
    </div>
     </Router>
  );
}

export default App;
