
import apiReq from "./apiReq"
function CompanysApi({companys,url}) {
    async function handleDelete(id) {
        const myurl = url+"/companys/"+id
        try{
            const options = {
                method:"DELETE"
            }
            const result = await apiReq(myurl,options)
        }catch(err){
            console.log(err);
        }finally{
            // window.location.href = window.location.href;
            window.location.reload();
        }
    }
    let date = new Date();
    return(
        <div className="admin-info-r">
            <div>
                <span>NAME</span>
            </div>
            {companys.length>0?companys.map((item)=>{

                return(
                    
                    <div>
                <span>{item.name}</span>
              
                <button type='button' onClick={()=>handleDelete(item._id)}>delete</button>
                
            </div>
            )
                }):null
            }
        </div>
    )
}
export default CompanysApi