import { Link } from "react-router-dom"
import "./card.css"
import "./phone.css"
import apiReq from "./apiReq"
import { FaRoad , FaDoorClosed, FaPerson, FaBriefcase, FaWhatsapp,FaPhone,FaTrash,FaGlobe, FaStar} from "react-icons/fa6"

async function handleDelete(id) {
    const url = "http://localhost:3003/items/"+id
    const options = {
        method:"DELETE"
    }
    const result = await apiReq(url,options)
}

function Card({data,isAdmin}) {
    let text = `مرحبا ارغب بحجز ${data.name} ${'موديل'} ${data.model} ${data.company} ${data.priceM}`
    return(
        <Link to={`/cars/${data._id}`} >
        <div className="card">
            {/* <img src={data.img} alt="car image" /> */}
            <div className="card-img" style={{backgroundImage:`url(${data.img})`}}></div>
            <div className="card-bad">
            {data.special&&<FaStar id="star"/>}
            <h1>{data.name} {data.model}</h1>
            {data.dis?<p>{data.dis}</p>:null}
            <div className="card-icons">
                {/* <div><FaRoad/> {data.kilos}/كيلومتر </div> */}
                <div id="compIcon"> {data.company} <FaGlobe/></div>
                {/* <div><FaPerson/> {data._id}</div> */}
                {/* <div><FaBriefcase/> {data.pags}</div> */}
            </div>
            <div className="card-prices">
                <h1><span>الحالة</span>{data.status == 'excellent'? ' ممتازة ':' متوسطة '}</h1>
                <h1><span>سعر الايجار </span>{" "+data.priceM+" درهم"}</h1>
            </div>
            </div>
            <div className="card-btns">
            {/* <div id="green" onClick={()=>{window.location.href="https://web.whatsapp.com/send/?phone=%2B971556678100&text=demo&type=phone_number&app_absent=0"}}> */}
                <Link to={`https://api.whatsapp.com/send/?phone=%2B971556678100&text=${text}&type=phone_number&app_absent=0`} target="_blank" id="green">
                    <FaWhatsapp/> 
                    <h1>
                         احجز الان
                         
                    </h1>
                    </Link>
                    <Link to='/contact' target="_blank"><FaPhone/> <h1> اتصل  </h1> </Link>
               {isAdmin&& <div id="delete" onClick={()=>handleDelete(data._id)}><FaTrash/> <h1> حزف  </h1> </div>}
            </div>
        </div>
        </Link>
    )
}
export default Card