
import { Link } from "react-router-dom"
import apiReq from "./apiReq"
function CarsApi({data,url}) {
    async function handleDelete(id) {
        const myurl = url+"/items/"+id
        const options = {
            method:"DELETE"
        }
        const result = await apiReq(myurl,options)
        window.location.reload()
    }
    // let date = new Date();
    console.log("car log :");
    // console.log(data[0].name);
    return(
        <div className="admin-info-r">
            <div>
                <span>NAME</span>
                <span>MODEL</span>
                <span>STATUS</span>
                <span>AVAILABLE</span>
                <span>SPECIAL</span>
                <span>DATE</span>
                <span>DELETE</span>
                <span>UPDATE</span>
            </div>
            {data.length >0 ?data.map((item)=>{
                return(
                <div>
                <span>{item.name}</span>
                <span>{item.model}</span>
                <span>{item.status}</span>
                {/* <span>{!item.available&&"not "}available</span> */}
                <input type="checkbox" checked={item.available}/>
                <input type="checkbox" checked={item.special}/>
                <span>{item.date}</span>
                <button onClick={()=>handleDelete(item._id)}>delete</button>
                <Link to={`/admina2/editcar/${item._id}`} target="_blank">update</Link>
            </div>
            )}):null}
    
        </div>
    )
}
export default CarsApi